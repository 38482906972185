import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Microsoft365 = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/microsoft365" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Microsoft365," />
                    <title> Microsoft Partner | Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Microsoft Office 365
                        </h2>
                        <h1>
                            Stay connected anywhere anytime with Microsoft Office 365
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Moving to Microsoft Office 365</h3>
                                    <p >
                                        Microsoft 365 is a subscription based package which
                                        includes Business productivity tools, Exchange Email
                                        services, Cloud Storage, and Teams for Collaboration.
                                        With the Internet making business faster and more
                                        efficient, team collaboration, communication, and
                                        decision making have become much easier. Work should
                                        be done in a way that reduces the time and effort put together,
                                        thus increasing the efficiency and effectiveness.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='MicrosoftOffice365' title='MicrosoftOffice365' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5 " style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                Features of Microsoft Office 365
                            </h2>
                            <p>
                                Microsoft Office 365 stands out as a versatile and powerful solution
                                for individuals and businesses seeking to streamline their work
                                processes and enhance collaboration. With its array of productivity tools,
                                security features, and integration capabilities, Office 365 continues
                                to shape the way we work and communicate in today's digital age.
                            </p>
                        </div>
                        <div className="row p-lg-3 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >


                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/Microsoft1.png")} alt="Easy_collaboration" title='Easy_collaboration' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Easy collaboration
                                            </h2>
                                            <p>
                                                Collaborate in real-time with Microsoft 365's online office
                                                tools, including Word, Excel, and PowerPoint.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/Microsoft2.png")} alt="Team_Collaboration" title='Team_Collaboration' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Team Collaboration
                                            </h2>
                                            <p>
                                                Microsoft Teams enables seamless collaboration among groups,
                                                whether working remotely or in the same office,
                                                leading to enhanced productivity and efficiency.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/Microsoft3.png")} alt="Offline_Mode" title='Offline_Mode' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Offline Mode
                                            </h2>
                                            <p>
                                                Microsoft 365's automatic document saving feature ensures that your work is safe even
                                                in the event of an interrupted internet connection.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/Microsoft4.png")} alt="Mobile_Applications" title='Mobile_Applications' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Mobile Applications
                                            </h2>
                                            <p>
                                                Microsoft's mobile apps, like Outlook, Word, Excel, PowerPoint, Teams,
                                                and Microsoft 365 admin, allow for easy work on mobile devices.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/Microsoft5.png")} alt="Security_Compliance" title='Security_Compliance' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Security Compliance
                                            </h2>
                                            <p>
                                                Your email is protected against spam, malware, and known threats with helping
                                                you to work without worrying about data breach and theft.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/Microsoft6.png")} alt="In_line_reply_to_email" title='In_line_reply_to_email' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                In-line reply to email
                                            </h2>
                                            <p>
                                                Reply to emails from the Reading Pane in Outlook with
                                                ease by enabling the convenient preview feature.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/Microsoft7.png")} alt="Share_files" title='Share_files' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Share files usign Links
                                            </h2>
                                            <p>
                                                Share files easily by uploading them to cloud storage and inserting a link in
                                                your Outlook email instead of attaching the file directly.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/Microsoft8.png")} alt="Data_on_Cloud" title='Data_on_Cloud' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Data on Cloud
                                            </h2>
                                            <p>
                                                1 TB of OneDrive Cloud storage is included in Microsoft 365
                                                basic plans for easy access to your documents from anywhere.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className='container-lg FeatureMain'>
                        <div className='GoogleWorkspaceCollaboration Dosis'>
                            <h2>
                                Microsoft 365: collaborate with ease
                            </h2>
                            <p>
                                Microsoft 365 serves as a dynamic platform that not only facilitates collaboration but also
                                <br /> fosters innovation and efficiency within organizations of all sizes.
                            </p>
                        </div>
                        <div className='row text-center g-4 pb-5' >
                            <div className="col-lg-3 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop' >
                                    <div>
                                        <img className='' src={require("../Img/MicroSoftColl1.png")} alt="Word" title='Word' />
                                    </div>
                                    <h4>Word</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/MicroSoftColl2.png")} alt="Excel" title='Excel' />
                                    </div>
                                    <h4>Excel</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/MicroSoftColl3.png")} alt="PowerPoint" title='PowerPoint' />
                                    </div>
                                    <h4>PowerPoint</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/MicroSoftColl4.png")} alt="Outlook" title='Outlook' />
                                    </div>
                                    <h4>Outlook</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/MicroSoftColl5.png")} alt="OneNote" title='OneNote' />
                                    </div>
                                    <h4>OneNote</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/MicroSoftColl6.png")} alt="Defender" title='Defender' />
                                    </div>
                                    <h4>Defender</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/MicroSoftColl7.png")} alt="OneDrive" title='OneDrive' />
                                    </div>
                                    <h4>OneDrive</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/MicroSoftColl8.png")} alt="Designer" title='Designer' />
                                    </div>
                                    <h4>Designer</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/MicroSoftColl9.png")} alt="Clipchamp" title='Clipchamp' />
                                    </div>
                                    <h4>Clipchamp</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don't miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className='container-lg FeatureMain'>
                        <div className='GoogleWorkspaceCollaboration Dosis'>
                            <h2>
                                Microsoft Office 365 prices
                            </h2>
                            <p>
                                When it comes to pricing, Office 365 gives you options like a choose-your-own-adventure book. Whether you want basic, standard, business or
                                premium there's a plan that fits your budget and preferences like a glove.
                            </p>
                        </div>
                        <div className='row text-center g-4 pb-5' >
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className='CollaborationNeedsTop' >
                                    <div className='mb-3'>
                                        <img className='w-100' src={require("../Img/MicrosoftAssistance1.png")} alt="MicrosoftAssistance" title='MicrosoftAssistance' />
                                    </div>
                                    <h4 style={{ lineHeight: "27px", fontSize: "20px" }}>Microsoft 365 Business Standard</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className='CollaborationNeedsTop'>
                                    <div className='mb-3'>
                                        <img className='w-100' src={require("../Img/MicrosoftAssistance1.png")} alt="MicrosoftAssistance" title='MicrosoftAssistance' />
                                    </div>
                                    <h4 style={{ lineHeight: "27px", fontSize: "20px" }}>Microsoft 365 Apps for Business</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className='CollaborationNeedsTop'>
                                    <div className='mb-3'>
                                        <img className='w-100' src={require("../Img/MicrosoftAssistance1.png")} alt="MicrosoftAssistance" title='MicrosoftAssistance' />
                                    </div>
                                    <h4 style={{ lineHeight: "27px", fontSize: "21px" }}>Microsoft 365 Business Basic</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className='CollaborationNeedsTop'>
                                    <div className='mb-3'>
                                        <img className='w-100' src={require("../Img/MicrosoftAssistance1.png")} alt="MicrosoftAssistance" title='MicrosoftAssistance' />
                                    </div>
                                    <h4 style={{ lineHeight: "27px", fontSize: "20px" }}>Microsoft 365 Business Premium</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Microsoft365